.fade-enter {
	opacity: 0;
	z-index: 1;
}

.fade-enter-active {
	opacity: 1;
	transition: opacity 500ms ease-out;
}

.fade-exit {
	opacity: 1;
}

.fade-exit-active {
	opacity: 0;
	transition: opacity 500ms ease-out;
}

.swiper-scrollbar {
	background-color: #6c727f !important;
	width: 60% !important;
	margin-left: 20%;
	opacity: 1 !important;
	height: 8px !important;
}

.scrollbar-drag {
	border-radius: inherit;
}

.scrollbar-drag,
.swiper-scrollbar-drag {
	background-color: #b8b9c1 !important;
	transition: background-color 200ms !important;
}

.scrollbar-drag:active,
.swiper-scrollbar-drag:active {
	background-color: white !important;
}

.swiper-button-prev,
.swiper-button-next {
	position: absolute !important;
	top: calc(100% - 7px) !important;
	opacity: 1 !important;
}

.swiper-button-prev::after,
.swiper-button-next::after {
	color: #b8b9c1 !important;
	font-weight: bold;
	font-size: 14px !important;
	transition: color 200ms;
}

.swiper-button-prev {
	left: calc(20% - 30px) !important;
}

.swiper-button-next {
	right: calc(20% - 49px) !important;
}

.swiper-button-disabled::after {
	color: #363442 !important;
}

.swiper-button-prev:hover::after,
.swiper-button-next:hover::after {
	color: white !important;
}
